.header {
  width: 95%;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main {
  padding: 0 24px;
}

.home .main {
  flex-direction: column;
}


.upgrade {
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  margin-left: 8px;

  &:hover {
    transition: .3s;
  }
}
.logout {
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.875rem;
  margin-left: 8px;

  &:hover {
    transition: .3s;
  }
}

.profile {
  display: flex;
  justify-content: center;
  align-items: center;

  .name {
    margin-right: 8px;
    display: flex;
    align-items: center;
    gap: 4px;

    svg {
      color: #ff9200;
    }
  }
}

.adminControls {
  position: fixed;
  bottom: 0;
  right: 0;
}

.root {
  background-color: transparent;
  box-shadow: none;
}

.expanded {
  margin: 0;
  min-width: 48px;
}