.fieldContainer {
  position: relative;
}

.paymentDialog {
  max-width: 440px;
}

.formTitle {
  padding: 36px 36px 20px;
}

.formDescription {
  padding: 0 12px;
  max-width: 400px;
}

.paymentForm {
  display: grid;
  grid-column-gap: 10px;
  grid-template-columns: auto auto;
  grid-template-rows: 90px 90px 60px;
  grid-template-areas:
        "name name"
        "number number"
        "expiration security";
  max-width: 400px;
  padding: 12px;
}

.paymentForm .fieldContainer:first-of-type {
  grid-area: name;
}

.paymentForm .fieldContainer:nth-of-type(2) {
  grid-area: number;
}

.paymentForm .fieldContainer:nth-of-type(3) {
  grid-area: expiration;
}

.paymentForm .fieldContainer:nth-of-type(4) {
  grid-area: security;
}

.actions {
  justify-content: center;
  padding: 12px 12px 24px;
}

.loaderContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 64px 0;
}