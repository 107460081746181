.courseContainerTitle {
  display: block;
  padding-left: 20px;
}

.welcome {
  justify-content: center;
  display:flex;
}

.courseContainer {
  margin: 0;
}

.courseGrid {
  justify-content: center;
  align-items:center;
  display: grid;
  grid-template-columns: repeat(auto-fit,minmax(400px,1fr));
  grid-gap: 20px;
  padding: 20px;
}

.courseBox {
  height:200px;
  color: #ffffff;
  position: relative;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 2px rgba(9, 36, 51, 0.08), 0 4px 16px rgba(9, 36, 51, 0.04);
  background-size: cover;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  padding: 12px;

  .textContent {
    border-radius: 4px;
    display: flex;
    flex-direction: column;
  }

  .title {
    margin: 0 0 4px 0;
  }

  .description {
    margin: 0;
  }

  .actionButton {
    color: #ffffff;
    padding: 8px 24px;
    background: #2563fc;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    border: none;

    &:hover {
      background-color: #4e84f7;
      transition: .3s;
    }
  }
}
